import { graphql } from 'gatsby'
import React, { useEffect, useContext } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import AOS from 'aos'

import Layout from '../components/Layout'
import Section from '../components/Section'
import Hero from '../components/Hero'
import Link from '../components/Link'
import BookDemoSection from '../components/BookDemoSection'
import LocaleContext from '../components/LocaleContext'
import {bookADemoLinkSwitch} from '../components/lib/bookADemoLinkSwitch'
import ModalVideo from '../components/ModalVideo'
import RichText from '../components/RichText'

import './partner.scss'
import 'aos/dist/aos.css'
import bannerMyHR from '../images/integrations/MyHR-Partner-Benefits-Lockup.png'
import bannerImg from '../images/integrations/Sales_Tile.png'
import bookDemoImage from '../images/case-study/Sales_Tile_2022-04.png'


export default ({data}) => {
  const page = data.contentfulPartnerPage
  const { title, slug, bannerDescription, bodyText, bodyImage, partnerLogo, freeTrialLink, freeTrialText } = page
  const bannerText = bannerDescription && bannerDescription.childMarkdownRemark && bannerDescription.childMarkdownRemark.html

  useEffect(() => {
    // Animations
    AOS.init()
  }, [])

  const locale = useContext(LocaleContext)
  const demoLink = bookADemoLinkSwitch(locale.value)

  return (
    <Layout className={'PartnerLanding '+`${slug}`} title="" description="" locale="en-NZ">
      <Hero variant="">
        <img src={bannerMyHR} className="bannerMyHR" alt={title} />
        <div dangerouslySetInnerHTML={{ __html: bannerText }} />
      </Hero>

      <Section className="mt-md-6 mt-lg-0">
        <Container>
          <Row className="">
            <Col md={12} lg={12} className="mt-4">
              <Row>
                <Col md={6} className="d-flex" data-aos="fade-right" >
                  <div className="PartnerLanding__text w-100 pb-3 text-center py-md-5 text-md-left">
                    <div className="PartnerLanding__leftBox">
                      {partnerLogo && partnerLogo.file.url &&
                        <img src={partnerLogo.file.url} className="PartnerLogo" alt={title} />
                      }
                      <RichText json={bodyText.json} />
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} className="mb-3 mb-md-0" data-aos="fade-left" >
                  {bodyImage && bodyImage.file.url &&
                    <img src={bodyImage.file.url} className="Screenshot" alt={title} />
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Section>

      <BookDemoSection />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PartnerPageByUrl($slug: String!) {
    contentfulPartnerPage(slug: { eq: $slug }) {
      ...ContentfulPartnerPageFragment
    }
  }
`